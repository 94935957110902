<template>
      <v-app-bar
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      absolute
      color="dark-grey"
      dark
      scroll-target="#scrolling-techniques-6"
    >
<v-row
justify="center">
  
  <v-col
  cols="3"
  md="2">
  <v-row>
        <v-btn
        href="/home"
        text
      >
        <h1 class="v_app_bar">Home</h1>

      </v-btn>
  </v-row>
  </v-col>
  <v-col
  cols="3"
  md="2">
  <v-row>

        <v-btn
        href="/about"
        text
      >
        <h1 class="v_app_bar">About Us</h1>

      </v-btn>
    </v-row>
    </v-col>

<v-col
cols="1"
md="2">
<v-row>
    
              <v-btn
        href="/services/3d-printing"
        text
        class="d-none d-sm-block pt-3"
      >
        <h1 class="v_app_bar">3D Printing</h1>

      </v-btn>
</v-row>
</v-col>

<v-col
cols="1"
md="2">
<v-row>

              <v-btn
        href="/services/3d-modelling"
        text
        class ="d-none d-sm-block pt-3"
      >
        <h1 class="v_app_bar">3D Modelling</h1>

      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
  </v-col>


<v-col
cols="3"
md="2">
<v-row>

  <v-menu offset-y class="d-flex d-sm-none">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="d-flex d-sm-none"
          color="dark-grey"
          dark
          text
          v-bind="attrs"
          v-on="on"
        >
       <h1 class="v_app_bar">Services</h1>
        </v-btn>
      </template>
      <v-list class="v_app_bar_menu_item">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title> <a class="v_app_bar_menu_item" :href=item.link>{{ item.title }}</a></v-list-item-title>
        </v-list-item>

      </v-list>
    </v-menu>

      <v-btn
        href="https://docs.google.com/forms/d/e/1FAIpQLSfFIZAUx_ALZV1IbL2gA-Gxt4jUAkgKXAjs5we7CufA06RjFg/viewform?usp=pp_url"
        target="_blank"
        text
        class="d-none d-sm-block"
      >
        <span class="mt-3"><h1 class="v_app_bar">Get your quotation</h1></span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      </v-row>
      </v-col>


    </v-row>
    </v-app-bar>
</template>

<script>
export default {
    data: () => ({
        collapseOnScroll: true,
        items: [
        { title: '3D Printing', link:"/services/3d-printing" },
        { title: '3D Modelling', link:"/services/3d-modelling" },

        ]
    //
    }),
}
</script>

<style>

</style>