
<template>

  <div class="background ">
    
    <div>

      <v-parallax 
      src="../img/rsz_1osman-talha-dikyar-nmcabehn0re-unsplash.avif"
      height="700"
      >

<v-row
class="pt-16">
<v-row
class="pt-16">
      <v-col
      cols="12"
      class="text-center pt-16 "
      >
      <h1 class="text_fade">Your imagination starts here!</h1>
      <h2 class="text_fade">JustPrintLah is where we turn your new ideas into reality. By using 3D printing technology, we can turn your 
        2D designs into 3D models. To get started, you can send in your 3D models for us to give you a free quote!
      </h2>      
</v-col>
</v-row>
</v-row>
      
<v-row
class="mt-n16 d-flex d-sm-none"
justify="center">

<v-btn
  class="font-weight-bold white--text"
  rounded
  large
  color="primary"
  dark
  href='https://docs.google.com/forms/d/e/1FAIpQLSfFIZAUx_ALZV1IbL2gA-Gxt4jUAkgKXAjs5we7CufA06RjFg/viewform?usp=pp_url'
  >
      Get your quotation
</v-btn>



      </v-row>
      
<v-row
class="mt-n16 d-flex d-sm-none"
justify="center">
<v-btn
  class="font-weight-bold black--text"
  rounded
  large
  color="#25D366"
  dark
  href="//api.whatsapp.com/send?phone=601120969166&text=Hi! I would like to request for a quotation."
  >
  WhatsApp Us
</v-btn>
      </v-row>
</v-parallax>
    </div>


<div>
  <v-parallax 
  src="../img/rsz_2vinicius-amnx-amano-6zwdveabfme-unsplash.avif"
  height="700"
  >  
    <v-col 
    cols="8"
    md="5"
    sm="5"
    xs="12"
>

      <v-row
      class="ps-6 text-left" >
      <h2>
          With the gaining popularity in 3D printing in recent times,
          our aim is to provide affordable printing solutions to our clients
          from prototyping to printing.</h2>
          </v-row>
         
<v-row
class="pa-6 pt-16">
  <v-btn
  class="font-weight-bold"
  color="primary"
  depressed
  elevation="2"
  rounded
  large
  href="/about"
>Learn about JustPrintLah</v-btn>
</v-row>
</v-col>
    </v-parallax>
  </div> 


<div>
  <v-img
  src="../img/kier-in-sight-7jzOMn19ByI-unsplash-_1_.avif"

  >  


    <v-row
    >
    <v-col
    cols="1"
    >
    <v-sheet
    class="text-center pa-1 d-none d-sm-block"
    color="grey darken-2"
    elevation="8"
    height="60"
    width="500"  
    >
    <h2> Our services  </h2>
    </v-sheet>

    <v-sheet
    class="text-center pa-2 pl-15  d-flex d-sm-none"
    color="grey darken-2"
    elevation="8"
    height="40"
    width="250"  
    >
    <h2> Our services  </h2>
    </v-sheet>
    </v-col>
    </v-row>


  <div>
<v-row
class="pt-16">
<v-row
class="pt-16">
<v-row
class="pb-16">
<v-col
md="6"
sm="10"
xs="10">  
<v-lazy>
<v-card
    class="mx-auto services_card"
    
  >
    <v-img
      lazy-src ="../img/11-10x6.jpg"
      src="../img/minku-kang-aCniNTiIFd8-unsplash.avif"
      height="200px"
    ></v-img>

    <v-card-title>
     <h3>3D Printing</h3> 
    </v-card-title>

    <v-card-subtitle>
     <h4> Convert your 3D models into physical plastic parts </h4>
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        class="font-weight-black"
        color="orange lighten-2"
        text
        href="/services/3d-printing"
      >
        Learn more
      </v-btn>

    </v-card-actions>

  </v-card>
</v-lazy>  

</v-col> 

<v-col
md="6"
sm="10"
xs="10">
<v-lazy>
     <v-card
    class="mx-auto services_card"
  >
    <v-img
      lazy-src ="../img/11-10x6.jpg"
      src="../img/shutterstock_782845423-900x400_1.avif"
      height="200px"
    ></v-img>

    <v-card-title>
     <h3> 3D Modelling</h3>
    </v-card-title>

    <v-card-subtitle>
     <h4> Don't have a 3D model? Convert your 2D ideas into 3D! </h4>
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        class="font-weight-black"
        color="orange lighten-2"
        text
        href="/services/3d-modelling"
      >
        Learn more
      </v-btn>
</v-card-actions>
  </v-card>
  </v-lazy>
  </v-col>
  </v-row>
  </v-row>
  </v-row>  
  </div> 
      </v-img>

    </div>




    <v-row
    >
    <v-col
    cols="1">
    <v-sheet
    class="text-center pa-1 d-none d-sm-block"
    color="grey darken-2"
    elevation="8"
    height="60"
    width="500"  
    >
    <h2>Testimonials</h2>
    </v-sheet>
    <v-sheet
    class="text-center pa-2 pl-15  d-flex d-sm-none"
    color="grey darken-2"
    elevation="8"
    height="40"
    width="250"  
    >
    <h2> Testimonials  </h2>
    </v-sheet>
    </v-col>
    </v-row>

<v-row
class="py-6"  
>
<FacebookReviews/>
</v-row>




    <v-row
    >
    <v-col
    cols="1">
    <v-sheet
    class="text-center pa-1 d-none d-sm-block"
    color="grey darken-2"
    elevation="8"
    height="60"
    width="500"  
    >
    <h2>Frequently Asked Questions</h2>
    </v-sheet>

    <v-sheet
    class="text-center pa-2 pl-15  d-flex d-sm-none"
    color="grey darken-2"
    elevation="8"
    height="40"
    width="400"  
    >
    <h2>Frequently Asked Questions</h2>
    </v-sheet>
    </v-col>
    </v-row>

<v-row
class="justify-center py-8"
>
<v-col
cols="8"
>

<FAQ> </FAQ>
</v-col>
</v-row>

</div>

</template>

<script>



import FacebookReviews from '../components/FacebookReviews.vue'
import FAQ from '@/components/FAQ.vue';


export default ({
  name: 'HomeView',
  components: {
    FacebookReviews,
    FAQ
},
  
  data:()=> ({
    show: false,
    title: 'JustPrintLah',
    sub_title: 'Bringing ideas to life',
    about_button: "Learn more",
    service_title_1: '3D Printing',
    service_title_2: '3D Modelling'
  })
,
methods:{
        aboutus_button:function(){
          window.location.href="/about"
        },
        redirect_3d_modelling_page(){
          window.location.href="/services/3d-modelling"
        }
},
metaInfo:{
    title: 'Home Page',
    meta:[
    { name: 'description', content:  'Do you require 3D printing or 3D modelling service? At JustPrintLah, we provide cheap and high quality 3D printing service for our customer! At only RM 0.3/g for PLA material and RM 0.4/g for PETG material, you can turn your ideas into reality. Learn more about JustPrintLah services today!'}
    ]
  }
})

</script>


<style>

.text_fade{
  animation: fadeIn 3s;
}
@keyframes fadeIn{
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

.home{
  background-color: #E5E5E5;
  font-style: normal;
}

.home h1{
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}

.main_title{
  
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.main_title h1{
  font-size: 100%;
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  animation: fadeIn 3s;
}
@keyframes fadeIn{
  0% {opacity: 0;}
  100% {opacity: 1;}
}



.whatsapp_button {

    background-color:#25D366;
}

.whatsapp_button .hover{
    background-color: #fff;
    color: #0d172a;
}


.aboutus_button {
    left:100px;
    top: 100px;
  
}

.aboutus_button .hover{
    background-color: #fff;
    color: #0d172a;
}

.why_print{
  background-color: aliceblue;
  margin: 20px;
}

.why_print h1{
  font-size: 100%;
  font-style: italic;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  animation: fadeIn 3s;
}

.why_print h2{
  margin: 10px;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #444;
  text-align:justify;
  font-family:  "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  white-space:pre-wrap;
}

.flex_container {
  display: flex;
  background-color: #444;


}
.flex_container div{
  background-color: white;
  margin: 10px;
  padding: 60px;
  font-size: 20px;
  color:aliceblue;
  flex-grow: 1;
  flex-basis: 100%;
}

.flex_container h1 {
  color:aliceblue;
  font-size: 50px;
  justify-content: center;
  text-align: center;

}

.flex_container h2 {
  color:aliceblue;
  font-size: 30px;
  justify-content: center;
  text-align: center;

}


.home h1{
  font-size: 50px;
  text-align: center;

}
.home h2{
  font-style: italic;
  text-align: center;
}

.home a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 35px;
  align-content: left;

}

img{
  float: right;
  width:1000px;
}

.service_flex_box{
  display: flex;
  justify-content: space-evenly;

}
.service_flex_box h1{
    text-align: center;
    font-style: normal9;
    font-size: 40px;
    color: #fff;
    font-family:  "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    
}
.service_flex_box h2{
    text-align: center;
    font-size: 25px;
    color: #fff;
    font-family:  "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    white-space:pre-wrap;
}

.service_flex_box p{
    text-align: center;
    font-size: 15px;
    color: #fff;
    font-family:  "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    white-space:pre-wrap;
}

.subsection_header_1 {
  position: absolute;
  top: 0px;
  left: 0px;
}
.subsection_header_2 {
  position: absolute;
  top: 2000px;
  left: 50px;
}

.panel_header{
  background-color: blue;
}
</style>
