import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import PrintingView from "../views/PrintingView.vue"
import ModellingView from '../views/ModellingView.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)


const routes = [

  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },

  {
    path: '/services/3d-printing',
    name: '3d-printing',
    component: PrintingView
  },
  {
    path: '/services/3d-modelling',
    name: '3d-modelling',
    component: ModellingView
  },
  {
      path: '/quotation_form',
      beforeEnter() {location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfFIZAUx_ALZV1IbL2gA-Gxt4jUAkgKXAjs5we7CufA06RjFg/viewform?usp=pp_url'}

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
