<template>

<div class="background">
  <v-app>
  <v-card class="overflow-hidden">
    <AppBar></AppBar>


    <v-main>
          <router-view/>

    </v-main>
  </v-card>

<PageFooter></PageFooter>




  </v-app>
  </div>
</template>

<script>
import AppBar from './components/AppBar.vue';
import PageFooter from './components/PageFooter.vue';




export default {
    name: "App",
    metaInfo(){
      return{
            title: "JustPrintLah - Fast & Affordable Printing Service",
            htmlAttrs: {lang: 'en-US'},
            meta: [
                { charset: 'utf-8',
                  name: "description", 
                  content:'JustPrintLah is about providing fast and affordable 3D printing service to Malaysians. Our vision is to share and impact people about the beauty of 3D Printing.',
                  property: 'og:title', 

                }]
              }
            },
    components: { AppBar, PageFooter },
    data: () => ({ isMobile: false }),

    beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted () {
      this.onResize()

      window.addEventListener('resize', this.onResize, { passive: true })
    },

    methods: {
      onResize () {
        this.isMobile = window.innerWidth < 600
      },
    },
};
</script>


<style>

@font-face {
  font-family: "Montserrat_Thin";
  src: local("Montserrat_Thin"),
  url(./fonts/Montserrat_Thin.ttf) format("truetype");
}

</style>