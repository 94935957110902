<template>


    <v-expansion-panels accordian
  >
    <v-expansion-panel
    v-for="faq in faqs"
    :key = "faq.question"
    >


      <v-expansion-panel-header
      color="#F0F8FF"
      >
     <h3 class="faq_question"> {{faq.question}} </h3> </v-expansion-panel-header>
      <v-expansion-panel-content
      color="#F0F8FF"
      >
      <h4 class="faq_answer">{{faq.answer}} </h4> 
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
    data(){
        return{
        faqs :[
            {
                question: "How do I get my item printed?",
                answer: "Follow these steps"
            },
            {
                question: "What material do you use for printing?",
                answer: "If no specific material is specified, we will quote and print using PLA material. If you prefer other materials, we have PETG and ABS materials too."
            },
            {
                question: "How do I collect my item?",
                answer: "There's 2 options available; Self collection and postage / lalamove. For postage, it will be RM 8 for West Malaysia and RM 13 for East Malaysia"
            },
            {
                question: "What if I don't have a 3D model?",
                answer: "If you do not have a 3D model, you can opt for our 3D modelling service. Else, you can find a library of 3D models from wwww.thingiverse.com"
            },
            {
                question: "I have confirmed my order. What do I do next?",
                answer: "If you've confirmed your order, you will need to make the payment via online banking. The printing process will only begin once we've received your payment."
            },
        ]


        }

    }

}
</script>

<style>
.faq_answer{
    color: black;
}
.faq_question{
    color: black;
}
</style>