<template>
<div>

<div class="flex_container_1">
 
<v-row v-for="review in reviews" :key="review.comment"> 
<v-col>
    <v-card
    class="mx-auto"
    color="#F0F8FF"
    max-width="350"
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-facebook
      </v-icon>
      <span class="text-h6 font-weight-light black--text">Facebook</span>
    </v-card-title>

    <v-card-text class="text-h7 font-weight-bold-text-justify black--text" >
      {{review.comment}}
    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">
        <div v-if="review.gender == 'male'">
        <v-list-item-avatar color="grey darken-3">
          <v-img
            class="elevation-6"
            alt=""
            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
          ></v-img>
          </v-list-item-avatar>
          </div>
          
          <div v-else>
          <v-list-item-avatar color="grey darken-3">
          <v-img
            class="elevation-6"
            alt=""
            src="https://avataaars.io/"
          ></v-img>
          </v-list-item-avatar>
          </div>
        

        <v-list-item-content>
          <v-list-item-title class="black--text"> {{review.name}}</v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-calendar
          </v-icon>
          <span class="subheading mr-2 black--text">{{review.date}}</span>


        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
  </v-col>
</v-row>

</div>

</div>




</template>

<script>



export default {
  data:() => ({
      reviews: [
        {name: 'Croco Tai', 
        comment:"They replied to message promptly and got the work done real quick. It took only a few days for small pieces of models, impressive. Yes, they accept small order, which is very helpful. The pricing is affordable and reasonable. The quality is on bar. If you're rushing for assignment, this is the place you should look for.", 
        date:"19th July 2022",
        gender: "male"
        },
        {
          name:"Menchie Domanguera",
          comment:"Steady client here. I have them printing my nerf accessories and mod parts and their prints are of good quality. Service is great and the pricing is fair. Recommended!",
          date:"24 March 2022",
          gender:"male"
          },
        {
          name:"Joel Lim",
          comment:"Superb and reliable service by Sim. Was able to model my item exactly I wanted and they got it done quick. What's best is they offer such good services at an affordable fee.",
          date:"22 February 2022",
          gender:"male"
          },
                  {
          name:"Fess Azmi",
          comment:"Tried out JustPrintLah’s services after seeing their ads around and the whole experience from initial query to final product was great. Quality of printing was good. Highly recommended. And i look forward to more repeat orders. Two thumbs up.",
          date:"2 October 2021",
          gender:"male"
          },
          {
            name: "Yvonne Sing",
            comment: "We commissioned them for one of our events and they did a phenomenal job. Great service and efficient with the works! We look forward to more projects with them soon. 😁👍 Keep it up!",
            date: "29 October 2021",
            gender:"female",
          },
          {
            name:"Abd Rahman Nasaruddin",
            comment:"Ordered a set of speed loaders for my Rival Kronos and the details and quality matches the price they charged; it was worth it! Would definitely order more custom prints in the near future!",
            date:"6 July 2020",
            gender:"male"
          }

    
      ]
    })
  }

</script>

<style>

.flex_container_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;


}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>