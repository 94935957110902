<template>
  <div class="background">

<div>

<v-row
class='pa-md-16'>
<v-row
class='pa-md-16 '>
<v-row
class='pt-md-16'>



<v-sheet
  class="white--text pa-md-10 about_card"
  color="grey darken-2"
  elevation="8" 
>

<v-container>
  <v-row

  >
  <v-col
  md="8"
  sm="10"
  xs="12"
  >
  <v-row>
   <h1> About us</h1>
  </v-row>
  <v-row
  class="mt-8"
  justify
  >
  
 <h3>
 Since 2018, JustPrintLah has always been driven by the goal of providing 
 affordable 3D printing and modelling services to our customer. 3D printing
 has always been our passion as 3D printing capabilities are endless.
 We are certain that the future is heading towards a direction whereby manufacuturing
 can simply be done in a quick and simple manner without going through many processes.
 Our aim has always been providing outstanding printing quality at a low price.
 </h3> 

</v-row>
    </v-col>

<v-col
cols="12"
md="4"
sm="10"

class= 'pa-md-16 d-none d-sm-block'>
<v-img
  max-height="250"
  max-width="250"
  src="../img/logo.png">
  </v-img>
</v-col>
  </v-row>

</v-container>
</v-sheet>

</v-row>
</v-row>
</v-row>  

</div>

<div>
      <v-parallax 
      src="../img/rsz_1rsz_1rsz_1tom-claes-hmp2mme5yg0-unsplash.jpg"
      height="500"
      class="about_us_background"
      >

      <v-row
    >
    <v-col
    cols="1"
    >
    <v-sheet
    class="text-center pa-1 d-none d-sm-block"
    color="grey darken-2"
    elevation="8"
    height="60"
    width="500"  
    >
    <h2>Why choose us?</h2>
    </v-sheet>

    <v-sheet
    class="text-center pa-1 pl-12  d-flex d-sm-none"
    color="grey darken-2"
    elevation="8"
    height="40"
    width="250"  
    >
    <h2>Why choose us?</h2>
    </v-sheet>
    </v-col>
    </v-row>

<v-row>
  <v-col
  cols="12"
  md="4"
  sm="4"
  >

  <v-card
  elevation="12"
  outlined
  shaped
  tile
  max-width="400"
  color="#2E2E2E"
  height="200"
>
<v-card-title class="white--text"> <h3> Affordable Price</h3>
        <v-row
          align="center"
          justify="end"
          
        >
  <v-icon
  x-large
  dark
>
mdi-account-cash
</v-icon>
</v-row>
</v-card-title>
<v-card-text class="text-left white--text ">
  <h4>
  At JustPrintLah, our aim is to provide awareness to customer about the beauty of 3D printing.
  Hence, we've decided to provide the most affordable price for our customer so that this service
  is readily available for everyone!  </h4>
</v-card-text>
</v-card>
</v-col>

<v-col
  cols="12"
  md="4"
  sm="4"
  >


  <v-card
  elevation="12"
  outlined
  shaped
  tile
  max-width="400"
  color="#2E2E2E"
  height="200"
>
<v-card-title class="white--text"> <h3> Fast & Efficient </h3>
        <v-row
          align="center"
          justify="end"
          
        >
  <v-icon
  x-large
  dark
>
mdi-clock-fast
</v-icon>
</v-row>
</v-card-title>
<v-card-text class="text-left white--text ">
  <h4>We provide quick turnaround printing process to ensure that you get your project printed in time!
      Get your models printed within 2-3 days*. </h4>
</v-card-text>
<v-card-text class="text-left white--text text-caption ">
  <h4>*Duration depends on size of model </h4>
</v-card-text>
</v-card>

</v-col>

<v-col
  cols="12"
  md="4"
  sm="4"
  >


  <v-card
  elevation="12"
  outlined
  shaped
  tile
  max-width="400"
  color="#2E2E2E"
  height="200"
>
<v-card-title class="white--text"> <h3> Highly customizable</h3>
        <v-row
          align="center"
          justify="end"
          
        >
  <v-icon
  x-large
  dark
>
mdi-creation
</v-icon>
</v-row>
</v-card-title>
<v-card-text class="text-left white--text ">
 <h4> We offer a wide range of materials and colors suited for your design and our 3D modelling service 
  is able to modify your designs to best suite you.</h4>
</v-card-text>
</v-card>

</v-col>
</v-row>


</v-parallax>

</div>
  </div>

</template>

<script>
export default{
  metaInfo:{
    title: 'About Us',
    meta:[
    { name: 'description', content:  'Understand more about JustPrintLah and why choose us as your 3D printing provider'}
    ]
  }
}
</script>

<style>




</style>
