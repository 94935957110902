<template>


<v-col

>
  <v-card
    elevation="24"
    max-width="900"
    width="500"
    class="mx-auto modelling_card_for_carousel"
    outlined
    shaped
  >
    <v-carousel
    class="modelling_carousel"
    width="100"
    height="500"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
  >
  <v-carousel-item
  v-for="(pic,i) in modelling"
  :key="i"
  :src="pic.src"
  contain
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="300"
  width="700"
  class="modelling_carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  </v-card>
</v-col>

</template>

<script>
export default {
    data() {
        return {
         modelling: [
                {
                    src: require("../img/modelling/Screenshot 2022-08-21 112656.png"),
                },
                {
                    src: require("../img/modelling/299050600_429994435822022_4634212741656383376_n.jpg"),
                },
                {
                    src: require("../img/modelling/87440397_622888478498726_9010831766281256960_n.png"),
                },
                {
                    src: require("../img/modelling/305452012_515087650347015_1350956764387883109_n.jpg"),
                },
        ],

        
}
}
}
</script>

<style>

</style>