<template>
<div class="background">

<v-row
class='pa-md-16'>
<v-row
class='pa-md-16'>
<v-row
class='pt-md-16'>

<v-row
class="mb-6">
<v-sheet
  class="white--text pa-13  modelling_card"
  color="grey darken-3"
  elevation="8"
  height="250"
  width="1000"
  justify="center"
>

<v-row>
  <h2>
  What is 3D Modelling?</h2>
  </v-row>
  <v-row
  class="mt-8 text-left modelling_description_1 text-justify"
  >
  <h4>
      3D modelling is a process of creating a 3D representation
      of an object using a software. By manipulating vertices and edges
      in the software, each point will form a mesh and build a 3D model.
      3D modelling is commonly used in building engineering prototypes or
      functional models.</h4>
  </v-row>
    </v-sheet>
</v-row>
</v-row> 
</v-row> 
</v-row>  
<v-row
class="mb-12">
  <ModellingCarousel> </ModellingCarousel>
</v-row>       
<v-row
>

<v-sheet
  class="white--text pa-13 modelling_card_2"
  color="grey darken-3"
  elevation="8"
  height="400"
  width="1000"

>

<v-row>
 <h2> Turn your ideas into 3D model!  </h2>
  </v-row>
  <v-row
  class="mt-8 text-left"
  >
  <h4>
      Do you have a sketch of your idea or you need to replicate an existing model but
      you don't know how to 3D model? Let us help you to turn the 
      2D ideas into a 3D model! </h4>
  </v-row>

<v-row>
  
</v-row>

  <v-row
  class="mt-8">
<h2>How to get started? </h2>
  </v-row>

  <v-row
  class="mt-8"
  >
  <h4>Simply attach a drawing or sketch of your idea and fill up our quotation form  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfFIZAUx_ALZV1IbL2gA-Gxt4jUAkgKXAjs5we7CufA06RjFg/viewform?usp=pp_url"> here</a> </h4>
  </v-row>

  <v-row
  class="mt-8 text-h7 text-left modelling_description_1"
  >
  <h4>Need to chat with us to convey your ideas better? Whatsapp us  <a href="//api.whatsapp.com/send?phone=601120969166&text=Hi! I would like to request for a quotation."> here</a> </h4>
  </v-row>

    </v-sheet>
</v-row>

</div>
</template>

<script>
import ModellingCarousel from "@/components/ModellingCarousel.vue";

export default{

  metaInfo:{
    title: '3D Modelling',
    meta:[
    { name: 'description', content:  'Understand more about our 3D modelling services and its application'}
    ]
  },
    components: { ModellingCarousel}
}

</script>


<style>

.modelling_description_1 {
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #fff;
  font-style: italic;
  text-align: justify;

}




.modelling_description_2 {
  padding: 40px;
  position: absolute;
  top:600px;
  left: 5px;
  background-color: #444;
  box-sizing: border-box;
  width: 1000px;
  height: 400px;
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
}

.modelling_description_2 h1 {
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #fff;
  font-style: italic;

}

.modelling_description_2 p {
  margin: 10px;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #fff;
  text-align: justify;

}


</style>