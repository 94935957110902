<template>

<v-row
class="mb-6 carousel_picture"
>
<v-col
cols="10"
md="6"
sm="6"
>
  <v-card
    elevation="24"
    max-width="500"
    class="mx-auto carousel_card"
    outlined
    shaped

  >
    <v-carousel
    class="printing_card_carousel"
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
  >
  <v-carousel-item
  v-for="(pic,i) in student_project"
  :key="i"
  :src="pic.src"
  contain
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="500"
  width="500"
  class="carousel_item"

  >
  
  </v-carousel-item>
  </v-carousel>
  <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Student Projects</v-list-item-title>
          <v-list-item-subtitle>Here are some example of student projects printed by us!</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
                  </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</v-col>



  <v-col
cols="10"
md="6"
sm="6"
>
  <v-card
    elevation="24"
    max-width="500"
    class="mx-auto carousel_card"
    outlined
    shaped
  >
    <v-carousel
    class="printing_card_carousel"
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
  >
  <v-carousel-item
  v-for="(pic,i) in boardgames"
  :key="i"
  :src="pic.src"
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="300"
  width="800"
  class="carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Board games</v-list-item-title>
          <v-list-item-subtitle>Miniatures and boardgame props printed with various PLA material</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
                  </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>

  <v-col
  cols="10"
  md="6"
  sm="6"
>
  <v-card
    elevation="24"
    max-width="500"
    class="mx-auto carousel_card"
    outlined
    shaped
  >
    <v-carousel
    class="printing_card_carousel"
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
  >
  <v-carousel-item
  v-for="(pic,i) in nerf"
  :key="i"
  :src="pic.src"
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="300"
  width="800"
  contain
  class="carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Nerf mods</v-list-item-title>
          <v-list-item-subtitle>Customize your Nerf gun with these mods to make yours special!</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
                  </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>


   <v-col
cols="10"
md="6"
sm="6"
>
  <v-card
    elevation="24"
    max-width="500"
    class="mx-auto carousel_card"
    outlined
    shaped
  >
    <v-carousel
    class="printing_card_carousel"
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
  >
  <v-carousel-item
  v-for="(pic,i) in prototyping"
  :key="i"
  :src="pic.src"
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="300"
  width="800"
  class="carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  <v-list three-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Prototypes</v-list-item-title>
          <v-list-item-subtitle>Unsure if your ideas as working? 3D print them and test them out!</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
                  </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
  </v-col>

</v-row>
</template>

<script>
export default {
    data() {
        return {
         student_project: [
                {
                    src:  require('../img/student_project/IMG-20191219-WA0007.jpg'),
                },
                {
                    src: require('../img/student_project/305842789_604639534475308_8840965435570784915_n.jpg'),
                },
                {
                    src: require('../img/student_project/IMG-20201214-WA0007.jpg'),
                },
                {
                    src: require('../img/student_project/305531999_642027970542856_2835306143041975334_n.jpg')
                }
        ],
        boardgames :[
                {
                    src: require('../img/boardgames/243953099_1259765497817896_371146610382211284_n.jpg'),
                },
                {
                    src: require('../img/boardgames/IMG-20200202-WA0007.jpg'),
                },
                {
                    src: require('../img/boardgames/IMG-20200202-WA0008.jpg'),
                },
                {
                    src: require('../img/boardgames/IMG-20200208-WA0009.jpg')
                }
        ],
        nerf :[
                {
                    src: require('../img/nerf/IMG-20200629-WA0009.jpg'),
                },
                {
                    src: require('../img/nerf/IMG-20201127-WA0014.jpg'),
                },
                {
                    src: require('../img/nerf/IMG_20200314_101146.jpg'),
                },
                {
                    src: require('../img/nerf/IMG_20200314_101438.jpg'),
                }
        ],

        prototyping :[
                {
                    src: require('../img/prototype/305375815_660858625056423_5240021318694639567_n.jpg'),
                },
                {
                    src: require('../img/prototype/305974652_785296786014473_7915267727589863647_n.jpg'),
                },
                {
                    src: require('../img/prototype/305346449_776537396916171_5420440718792911614_n.jpg'),
                },
                {
                    src:require('../img/prototype/305245565_546686133929007_7772531495494113724_n.jpg'),
                }
        ],
        
}
}
}
</script>

<style>

</style>