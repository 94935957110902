<template>
  <v-footer

color="#2E2E2E"

height="300"
>
<v-row
  justify="center"
  
>
  
  <v-col
  cols="6"
  md="3"
  class="pr-md-10"
  >
  <v-row
  class="pb-md-6 text-h4 white--text">
    <h2>Contact</h2>  
  </v-row>
  <v-row>
  <v-text
  class="text-left pb-md-6 white--text" >
  <h4>Jalan PJS 10/11A, PJS 10, 46150 Petaling Jaya, Selangor</h4></v-text>
</v-row>
<v-row>
  <v-text
  class="text-left pb-md-6 white--text" >
  <h4>justprintlahco@gmail.com</h4> </v-text>
</v-row>
<v-row>
  <v-text
  class="text-left pb-md-6 white--text" >
 <h4>011 2096 9166</h4> </v-text>
  
   </v-row>
    </v-col>

   <v-col
  cols="3"
  md="3"
  class="pr-md-6"
  >
  <v-row
  class="pb-md-6 text-h4 white--text">
   <h2>Menu</h2> 
  </v-row>
  <v-row>
  <v-text
  class="text-left pb-1 white--text" 

  >
  <a class="footer_menu white--text" href="/home">Home</a>
  </v-text>
  </v-row>

  <v-row>
  <v-text
  class="text-left pb-1 white--text" 
  >
 <a class="footer_menu white--text" href="/about">About</a>
  </v-text>
  </v-row>
  
  <v-row>
  <v-text
  class="text-left pb-1 white--text" 
  >
 <a class="footer_menu white--text" href="/services/3d-printing">3D Printing</a>
  </v-text>
  </v-row>

  <v-row>
  <v-text
  class="text-left pb-1 " 
  >
 <a class="footer_menu white--text" href="/services/3d-modelling">3D Modelling</a>
  </v-text>
  </v-row>
    </v-col>

   <v-col
  cols="3"
  md="3"
  >
  <v-row
  class="pb-md-6 text-h4 white--text">
   <h2>Connect with us</h2> 
  </v-row>
  <v-row>
  <v-text
  class="text-left pb-1 white--text" 

  >
  <a class="footer_menu white--text" href="https://www.facebook.com/JustPrintLah">Facebook</a>
  </v-text>
  </v-row>

  <v-row>
  <v-text
  class="text-left pb-1 white--text" 

  >
  <a class="footer_menu white--text" href="https://www.instagram.com/JustPrintLah">Instagram</a>
  </v-text>
  </v-row>

  <v-row>
  <v-text
  class="text-left pb-1 white--text" 

  >
  <a class="footer_menu white--text" href="//api.whatsapp.com/send?phone=601120969166&text=Hi! I would like to know about your services">Whatsapp</a>
  </v-text>
  </v-row>


  </v-col>





  <v-col
    class="grey darken-2 py-4 text-center white--text"
    cols="12"
  >
    {{ new Date().getFullYear() }} — <strong>JustPrintLah</strong>
  </v-col>


</v-row>
</v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>