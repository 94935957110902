<template>


<v-col

>
  <v-card
    elevation="24"
    max-width="900"
    width="900"
    class="mx-auto material_carousel d-none d-sm-block"
  >
    <v-carousel
    cycle
    width="1000"
    height="500"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
    class="material_carousel_size"
  >
  <v-carousel-item
  v-for="(pic,i) in material"
  :key="i"
  :src="pic.src"
  contain
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="300"
  width="900"
  class="material_carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  </v-card>

  <v-card
    elevation="24"
    max-width="350"
    width="350"
    height="500"
    class="mx-auto d-flex d-sm-none"
  >
    <v-carousel
    cycle
    width="350"
    height="500"
    hide-delimiter-background
    show-arrows-on-hover
    delimiter-icon="mdi-minus"
    class="material_carousel_size"
  >
  <v-carousel-item
  v-for="(pic,i) in mobile_material"
  :key="i"
  :src="pic.src"
  contain
  reverse-transition="fade-transition"
  transition="fade-transition"
  height="1000"
  width="390"
  class="material_carousel_item"
  >
  
  </v-carousel-item>
  </v-carousel>
  </v-card>
</v-col>

</template>

<script>
export default {
    data() {
        return {
         material: [
                {
                    src: require('../img/material/PLA.jpg')
                },
                {
                    src: require('../img/material/PETG.jpg'),
                },
        ],
        mobile_material: [
                {
                    src: require('../img/material/Screenshot 2022-09-10 112918.png')
                },
                {
                    src: require('../img/material/Screenshot 2022-09-10 113000.png'),
                },
        ],

        
}
}
}
</script>

<style>

</style>