<template>
<div class="background">

<v-row
class='pa-md-16'>
<v-row
class='pa-md-16'>
<v-row
class='pt-md-16'>


<v-row
class="mb-6">
<v-sheet
  class="white--text pa-13 printing_card"
  color="grey darken-3"
  elevation="8"
  height="250"
  width="1000"
>

    <v-row>
  <h2>
  What is 3D Printing?</h2>
  </v-row>
  <v-row
  class="mt-8  text-justify printing_description"
  >
  <h4>
      3D Printing or commonly known as additive manufacturing is a process
      of creating a 3D object by combining layers of plastic together. Through
      this process, it can form intricate designs that are not easily done by 
      normal manufacturing process. 3D printing is generally used in prototyping,
      creating architecture models and even board game props! </h4>
  </v-row>
    </v-sheet>
</v-row>
</v-row> 
</v-row> 
</v-row>
<v-row>
<CarouselPicture></CarouselPicture>
</v-row>  


<v-row
class="mb-6"
>
<v-sheet
  class="white--text pa-13 material"
  color="grey darken-3"
  elevation="8"
  height="250"
  width="1000"
>
    <v-row>
  <h2> Materials </h2>
  </v-row>
  <v-row
  class="mt-8 text-h7 text-left "
  >
  <h4>
      There are many types of materials commonly used in 3D printing.
      The most common FDM 3D printing materials are PLA, ABS and PETG.
      Each of these materials have their various applications. Here are
      some of the materials that we offer: </h4>
  </v-row>
    </v-sheet>
</v-row>

<v-row
class="mb-6 justify-center"
>
<MaterialCarousel> </MaterialCarousel>
</v-row>

</div>
</template>

<script>
import CarouselPicture from "../components/CarouselPicture.vue";
import MaterialCarousel from "@/components/MaterialCarousel.vue";
export default{

  metaInfo:{
    title: '3D Printing',
    meta:[
    { name: 'description', content:  'Understand more about our 3D printing services and its application'}
    ]
  },

    data() {

        return {

        };
    },
    components: { CarouselPicture, MaterialCarousel }
}


</script>


<style>


.material {

  background-color: #444;
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  color: #fff;

}



</style>